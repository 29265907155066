/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  NavLink,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ExampleHeader from "components/Headers/ExampleHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <IndexNavbar />
      <ExampleHeader />
      <div className="main">
        <div className="section text-center">
          <NavLink to="/index" tag={Link}>
            <p className="link-black">Back to Main</p>
          </NavLink>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Kitchen Renovation</h2>
                <div className="overlay-container">
                  <div className="overlay-label">Pre-Renovation</div>
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/examples/example1_top.jpg").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div><br/></div>
                <div className="overlay-container">
                  <div className="overlay-label">Rendering</div>
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/examples/example1_middle.jpg").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div><br/></div>
                <div className="overlay-container">
                  <div className="overlay-label">Post-Renovation</div>
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/examples/example1_bottom.jpg").default}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
             </Row>
            </Container>
            <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Bathroom Renovation</h2>
                <div className="overlay-container">
                  <div className="overlay-label">Pre-Renovation</div>
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/examples/example2_top.jpg").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div><br/></div>
                <div className="overlay-container">
                  <div className="overlay-label">Rendering</div>
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/examples/example2_middle.jpg").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div><br/></div>
                <div className="overlay-container">
                  <div className="overlay-label">Post-Renovation</div>
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/examples/example2_bottom.jpg").default}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
             </Row>
            </Container>
            <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Bathroom Renovation</h2>
                <div className="overlay-container">
                  <div className="overlay-label">Pre-Renovation</div>
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/examples/example3_top.jpg").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div><br/></div>
                <div className="overlay-container">
                  <div className="overlay-label">Rendering</div>
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/examples/example3_middle.jpg").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div><br/></div>
                <div className="overlay-container">
                  <div className="overlay-label">Post-Renovation</div>
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/examples/example3_bottom.jpg").default}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
             </Row>
            </Container>
            <NavLink to="/index" tag={Link}>
            <p className="link-black">Back to Main</p>
            </NavLink>
          </div>
        </div> 
      <DemoFooter />
    </>
  );
}

export default LandingPage;
