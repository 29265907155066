/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    
    <footer className="footer footer-black  ">
      <Container>
        <Row style={{
          justifyContent: 'center'
        }}>
          <nav className="footer-nav" 
            
          >
            <ul>
        
              <li>
                <a
                  href="mailto:info@draftingby.design?subject=RE:%20Your%20Services"
                  target="_blank"
                  style={{fontSize:'120%'}}
                >
                  info@draftingby.design
                </a>
              </li>
         
              
            </ul>
          </nav>
          </Row>
          <Row>
     
          
            
             
              <a
                  href="https://www.creative-tim.com?ref=pkr-footer"
                  target="_blank"
                >
                  © {new Date().getFullYear()}, originally PaperKit by Creative Tim
                </a>
                <br/> 
                <hr/>
                <br/>
                <a
                  href="https://allnines.agency"
                  target="_blank"
                >
                  ALLNINES.agency
                </a>
              
           
            
        
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
