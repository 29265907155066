/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionLogin() {
  return (
    <>
      <div
        className="section section-image "
        // style={{
        //   backgroundImage:
        //     "url(" + require("assets/img/blurred-image-1.jpg").default + ")",
        // }}
      >
        <Container>
          <Row>
            <Col className="mx-auto" lg="10" md="2">
              <Card className="card-plain">
                <h3 className="title mx-auto">Our Services</h3>
                <div className="social-line text-center">
                  <p className="description">
                Drafting by Design offers 2D drafting services for remodel projects, new homes,
interior space planning and design. We provide floor plans, renderings and interior elevations for your client 
so they can easily visualize their home's new look. Drafting by Design works with Interior Designers to
include all information relevant to their clients and necessary for their contractors.
Our deliverables are easy to understand, accurate drawings that convey a personalized touch. 
<br/>
<br/>
At Drafting by Design, we
believe that drafting is a form of art. Our drafters, like any other good artists,
produce work that is aesthetically pleasing to our clients. We have been providing drafting services for over 25 years. We strive to offer the
highest value to you, our clients. Be it residential or commercial, Drafting by Design will meet or exceed your expectations. <br/>
<a href="mailto:info@draftingby.design?subject=RE:%20Your%20Services" style={{color: 'rgba(255,255,255)'}}>Contact us</a> today to see how we can help you realize the full potential of your designs.
</p>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionLogin;
